import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./Provider/AuthGuard.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./Pages/home/home.module").then((m) => m.HomePageModule),
  },

  // {
  //   path: 'sort-modal1',
  //   loadChildren: () => import('./Pages/sort-modal1/sort-modal1.module').then( m => m.SortModal1PageModule)
  // },
  // {
  //   path: 'sort-modal2',
  //   loadChildren: () => import('./Pages/sort-modal2/sort-modal2.module').then( m => m.SortModal2PageModule)
  // },

  {
    path: "signup",
    loadChildren: () =>
      import("./Pages/signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: "signin",
    loadChildren: () =>
      import("./Pages/signin/signin.module").then((m) => m.SigninPageModule),
  },

  {
    path: "forgot-password",
    loadChildren: () =>
      import("./Pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "otp",
    loadChildren: () =>
      import("./Pages/otp/otp.module").then((m) => m.OTPPageModule),
  },
  {
    path: "set-new-password",
    loadChildren: () =>
      import("./Pages/set-new-password/set-new-password.module").then(
        (m) => m.SetNewPasswordPageModule
      ),
  },

  // {
  //   path: 'success-modal',
  //   loadChildren: () => import('./Pages/success-modal/success-modal.module').then( m => m.SuccessModalPageModule)
  // },

  // {
  //   path: 'cancel-booking',
  //   loadChildren: () => import('./Pages/cancel-booking/cancel-booking.module').then( m => m.CancelBookingPageModule)
  // },
  // {
  //   path: 'my-appointment',
  //   loadChildren: () => import('./Pages/my-appointment/my-appointment.module').then( m => m.MyAppointmentPageModule)
  // },

  // {
  //   path: 'profile',
  //   loadChildren: () => import('./Pages/profile/profile.module').then( m => m.ProfilePageModule)
  // },
  {
    path: "edit-profile",
    loadChildren: () =>
      import("./Pages/edit-profile/edit-profile.module").then(
        (m) => m.EditProfilePageModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "settings",
    loadChildren: () =>
      import("./Pages/settings/settings.module").then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./Pages/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },

  {
    path: "",
    redirectTo: "/tabs/home",
    pathMatch: "full",
  },
  {
    path: "change-password",
    loadChildren: () =>
      import("./Pages/change-password/change-password.module").then(
        (m) => m.ChangePasswordPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
